import React from 'react';
import styled from 'styled-components';

import { useFetchApi } from '../libs/useDataApiV2';
import MasterPage from './MasterPage';
import { iPhoneWidth, desktopWidth, StyledErrorText } from '../libs/libSupport';

import app from '../appData';
import api from '../api-url';
import { WebsiteTagRecord, WebsiteTextContentType, WebsiteTextListContentType } from '../interfaces/lib-websites-interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatImageUrl } from '../libs/ImageFormatter';

const MasterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: ${desktopWidth}px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`
const ImagesAndPoints = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: top;
    width: 100%;
`
const LrImageContainer = styled.div`
    margin-right: 14px;
    flex-basis: 25%;
    `
const LrImage = styled.img<{ marginLeft: number; imageBorder: string }>`
    width: 100%;
    height: auto;
    display: block;
    margin-left: ${props => props.marginLeft}px;        // right hand image needs -18px to comp for border
    border: ${props => props.imageBorder};
`
const BulletPointText = styled.div`
    flex-basis: 40%;
    list-style-type: none;
    margin: 32px 18px 0 12px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    p {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    @media (max-width: ${iPhoneWidth}px) {
        font-size: 14px;
        line-height: 18px;
        margin-top: 0;
    }
`
const HomePageText = styled.p`
    font-size: 18px;
    text-align: left;
    line-height: 26px;
    width: 100%;
    margin-left: 16px;
    margin-right: 8px;
    @media (max-width: ${iPhoneWidth}px) {
        font-size: 14px;
        line-height: 18px;
        width: 90%;
        text-align: center;
    }
`
interface HomePageContentRecord {
    appList: WebsiteTextListContentType;        // tag: 'appList'
    copy: WebsiteTextContentType;               // tag: 'copy'
    [key: string]: any
}
const Home: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [content, setContent] = React.useState<HomePageContentRecord>();
    const { fetch, isFetchLoading } = useFetchApi();
    const navigate = useNavigate();
    const location = useLocation();

    const fetchErrorMsg = "Thank you for visiting Laurainbo Crystals. Our server is down right now and should be back shortly. Please try again in a little while."

    const gotData = (data: WebsiteTagRecord[]) => {
        const record = {} as HomePageContentRecord;
        record[data[0].tag] = data[0].content;
        record[data[1].tag] = data[1].content;
        setContent(record);
    }
    const isDashboard = location.search.includes("dashboard");
    React.useEffect(() => {
        fetch(api.fetchContent + "home/" + (isDashboard ? 1 : 0), null, gotData, () => setErrorMsg(fetchErrorMsg));
    }, []);

    if (app.isDashboard) {
        navigate("/dashboard");
    }
    return (
        <MasterPage>
            {content &&
                <MasterContainer>
                    <h1>Laurainbo Crystals</h1>
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    <ImagesAndPoints>
                        <LrImageContainer>
                            <LrImage marginLeft={0} imageBorder={app.themes.imageBorder} src={formatImageUrl("other/crystal_in_hand_nb.jpg")} alt="Crystal in hand" />
                        </LrImageContainer>
                        <BulletPointText>
                            {content!.appList.map(((point: string) => {
                                return (
                                    <p key={point}>{point}</p>
                                )
                            }))}
                        </BulletPointText>
                        <LrImageContainer>
                            <LrImage imageBorder={app.themes.imageBorder} marginLeft={-18} src={formatImageUrl("other/crystal_ball_nb.jpg")} alt="Crystal ball" />
                        </LrImageContainer>
                    </ImagesAndPoints>
                    <HomePageText dangerouslySetInnerHTML={{ __html: content.copy.text }} />
                </MasterContainer>
            }
        </MasterPage>
    )
}

export default Home;
