import path from "path-browserify";

import { insert } from "./libSupport";
import { cdnDomain, ImageFileOptions, ImageRecord, VideoStreamSource } from "../interfaces/lib-api-interfaces";

import app from '../appData';

export const videoHeightFactor = .56;

// use this if image could be video or blob; (use formatImageUrl if filename only)
export const formatImageOrVideoSrcWithFile = (image: ImageRecord, fileOptions?: ImageFileOptions): string => {
    if (image.stream_thumb_src) {
        return image.stream_thumb_src;
    }
    return (image.file ? URL.createObjectURL(image.file) : formatImageUrl(image.filename!, fileOptions));
}

// if this is on nginx server we just need https://domain or  https://CDN-DOMAIN
// if this is on local dev server, domain depends on whether this is dashboard and whether image is on cdn
const formatDomainPart = (isCdn?: boolean) => {
    if (window.location.hostname !== "localhost") {
        // on remote server
        return "https://" + (isCdn ? cdnDomain : app.domain);
    } else {
        if (app.isDashboard) {
            let result = "http://localhost:4000/";
            if (isCdn) {
                result += cdnDomain + "/cdn/";
            } else {
                result += app.domain + "/public/";
            }
            return result;
        } else {
            return process.env.PUBLIC_URL + "/";
        }
    }
}
// if size is passed it is inserted with underscore before file extension
// e.g.: insert("hello.jpg", "f") -> "hello_f.jpg"
// dashboard uses targetDomain as root, otherwise use app.productGraphicsBaseUrl (PUBLIC folder if running locally)
// app.domain = "laurainbocrystals.com" or "sam.bond" etc. (if this is dashboard the targetDomain is to the production site)
// if graphics are on cdn assume that they will be read off remote server even if this is dev server (app.allGraphicsLocal=true to override)
export const formatImageUrl = (filename: string, fileOptions?: ImageFileOptions) => {
    if (!filename) {
        return '';
    }
    // domainPart takes us through .com or .lol etc.
    const domainPart = formatDomainPart(fileOptions?.isCdn);
    const subFolder = "graphics/" + (fileOptions?.graphicsSubfolder ? (fileOptions.graphicsSubfolder) : '');
    const filenamePart = (fileOptions?.sizeDesignator && filename.length > 4) ? insert(filename, filename.length - 4, "_" + fileOptions.sizeDesignator) : filename;
    const url = domainPart + "/" + path.join(subFolder, filenamePart);
    //   console.log("formatImageUrl:", { domainPart, subFolder, filenamePart, url });
    return url.replace("//graphics", "/graphics");
}
export const formatYoutubeThumbUrl = (id: string): string => {
    return "https://img.youtube.com/vi/" + id + "/0.jpg";
}
export const formatVimeoApiUrl = (id: string): string => {
    return "http://vimeo.com/api/v2/video/" + id + ".json";
}
export const formatVideoIframeSrc = (streamSource: VideoStreamSource, streamId: string): string => {
    return (streamSource === VideoStreamSource.vimeo ? "https://player.vimeo.com/video/" : "https://www.youtube.com/embed/") + streamId  + "?wmode=transparent";
}
// remove domain info and sizing info; return filename itself
export const deformatImageUrl = (url: string, hasSizeDescriptor: boolean): string => {
    const nameStartPosn = url.lastIndexOf('/') + 1;
    let filename = url.substring(nameStartPosn);
    return filename.replace("_f.", '.').replace("_m.", '.');
}

export const defaultCaptionStyles = (): Record<string, any> => {
    const styles: Record<string, any> = {};
    for (const record of
        [
            { name: "captionFontSize", initialValue: 14 },
            { name: "captionFontSize-ipad", initialValue: 13 },
            { name: "captionFontSize-mobile", initialValue: 11 },
            { name: "captionItalics", initialValue: true },
            { name: "captionAlign", initialValue: "center" }
        ]) {
        styles[record.name] = record.initialValue;
    };
    return styles;
}
// use following when loading html docs that include images or videos
// fileOptions is used for graphicsSubfolder and sizeDesignator; if not passed, subfolder omitted and no size inserted into filename
// domain id under cdn folder is taken from app.domain (see formatImageUrl for all rules)
// if image is external and should not be formatted, attribute "isExternal" must be set to true
export const activateImagesAndVideos = (root: HTMLElement, fileOptions: ImageFileOptions, skipVideos: boolean) => {
    const figures = root.getElementsByTagName('figure');
    for (let i = 0; i < figures.length; i++) {
        const img = figures[i].getElementsByTagName("img")[0];
        if (figures[i].getAttribute("data-stream-id")) {
            // this is a video
            if (!skipVideos) {
                //              console.log("building iframe")
                const iframe = createIframeFromFigureElement(figures[i]);
           //                   console.log("iframe:", iframe)
                figures[i].replaceChild(iframe, img)
            }
        } else {
            if (img.getAttribute("isExternal") !== "true") {
                img.setAttribute("src", formatImageUrl(figures[i].getAttribute("data-file")!, fileOptions));
            }
        }
    }
}
const createIframeFromFigureElement = (fig: HTMLElement): HTMLElement => {
    return createIframe(fig.getAttribute("data-stream-source") as VideoStreamSource, fig.getAttribute("data-stream-id")!);
}
// image must have valid video fields
export const createIframeFromImageRecord = (image: ImageRecord): HTMLElement => {
    return createIframe(image.stream_source!, image.stream_id!);
}
const createIframe = (streamSource: VideoStreamSource, streamId: string): HTMLElement => {
    // create iframe element
    const iframe = document.createElement('iframe');
    const src = formatVideoIframeSrc(streamSource, streamId);
    if (streamSource === VideoStreamSource.vimeo) {
        iframe.setAttribute("allow", "autoplay; fullscreen; picture-in-picture");
    } else {
        iframe.setAttribute("allow", "web-share");
    }
    iframe.setAttribute("allowfullscreen", '');
    iframe.setAttribute("src", src);
    iframe.style.setProperty("aspect-ratio", "16/9");
    iframe.style.width = "100%";
    return iframe;
}


// #endregion SUPPORT FOR HTMLEDITOR and INFOPAGEVIEWER

/*
const iframe = document.createElement('iframe');
const src = formatVideoIframeSrc(image);
if (image.stream_source === VideoStreamSource.vimeo) {
    iframe.setAttribute("allow", "autoplay; fullscreen; picture-in-picture");
} else {
    iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share");
}
iframe.setAttribute("allowfullscreen", '');
iframe.setAttribute("src", src);
iframe.setAttribute("width", figSize + '');
iframe.setAttribute("height", Math.floor(figSize * videoHeightFactor) + '');
iframe.setAttribute("data-stream-id", image.stream_id);
iframe.setAttribute("data-stream-source", image.stream_source + '');
fig.appendChild(iframe);
*/