import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { getNearestParentId } from './libSupport';

import { MenuItemData } from '../interfaces/lib-api-interfaces';

import app from '../appData';
import { useGlobalContext, useSessionStore } from './SamState';

const smallNavFontWidth = 750;

export const hideNavBarKey = "hideNavBar";

// parse menu and popup indexes out of event.target.id
// is is coded "bhhvvyy" where hh is horizontal menu item (or button) and (optional) vv is dropdown1 item (or menu item) and (optional) yy is dropdown2 item, all with leading 0
//      e.g.: "File->Save File->Cancel" -- File->Save is b0000 and File->cancel is b0001
// returns ParsedIndexesRecord (value -1 for undefined)
interface ButtonIndexesRecord {
    target: HTMLDivElement;
    mainIndex: number;
    sub1Index: number;
    sub2Index: number;
    timerId?: NodeJS.Timeout;
}
const parseIndexes = (target: HTMLDivElement): ButtonIndexesRecord => {
    target = getNearestParentId(target) as HTMLDivElement;
    return {
        target,
        mainIndex: parseInt(target.id.substring(1, 3)),
        sub1Index: target.id.length > 3 ? parseInt(target.id.substring(3, 5)) : -1,
        sub2Index: target.id.length > 5 ? parseInt(target.id.substring(6, 8)) : -1
    };
}
const AddLeadingZero = (index: number): string => {
    return index < 10 ? ("0" + index) : index + '';
}
// return "bxxyyzz" where yy and zz are optional
const formatNavButtonId = (mainIndex: number, sub1Index: number = -1, sub2Index: number = -1): string => {
    return "b" + AddLeadingZero(mainIndex) + (sub1Index === -1 ? '' : AddLeadingZero(sub1Index)) + (sub2Index === -1 ? '' : AddLeadingZero(sub2Index));
}

// MasterContainer is placeholder for fixed positioned popups
const MasterContainer = styled.div<{ $fontFamily: string; $fontWeight: string }>`
    position: relative;
    font-family: ${props => props.$fontFamily};
    font-weight: ${props => props.$fontWeight};
`
const MainMenuContainer = styled.div<{ $backColor?: string; $backgroundImage?: string; $height: number }>`
    background-color: ${props => props.$backColor};
    background-image: ${props => props.$backgroundImage};
    background-size: ${props => props.$backgroundImage ? "cover" : null};
    width: 100%;
    height: ${props => props.$height}px;
    section {
        height: 100%;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
`
const MenuButtonContainer = styled.section<{
    $isVertical: boolean; $backColor: string; $foreColor: string; $fontSize: number; $top?: number; $left?: number;
    $hoverForeColor: string; $hoverBackColor: string;
}>`
    position: ${props => props.$isVertical ? "fixed" : "relative"};
    z-index: 1000;
    background-color: ${props => props.$backColor};
    color: ${props => props.$foreColor};
    font-size: ${props => props.$fontSize}px;
    display: flex;
    flex-direction: ${props => props.$isVertical ? "column" : "row"};
    justify-content: ${props => props.$isVertical ? "flex-start" : "space-around"};
    align-items: ${props => props.$isVertical ? "flex-start" : "center"};
    top: ${props => props.$top}px;
    left: ${props => props.$left}px;
    div:hover {
        background-color: ${props => props.$hoverBackColor};
        color: ${props => props.$hoverForeColor};
        cursor: pointer;
    }
    div {
        background-color: ${props => props.$backColor};
    }
    text-align: ${props => props.$isVertical ? "left" : "center"};
`
const DropdownButton = styled.div`
    border: 1px solid;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
`
interface NavButtonOptionsRecord {
    height?: number;
    foreColor?: string;
    backColor?: string;
    backgroundImage?: string;
    hoverForeColor?: string;
    hoverBackColor?: string;
    padding?: number;
    fontSize?: number;
    fontWeight?: string;
}
// NOTE: NavBar can be hidden by setting "hideNavBar" variable in global context; use this when enlarging product images so they don't interfere with navbar
interface NavBarProps {
    menuItems: MenuItemData[];
    id?: string;     // must be unique if multiple navbars on a page
    fontFamily?: string; // defaults to appData.themes.navbarFont
    mainOptions?: NavButtonOptionsRecord;    // horizontal main menu
    dropdownOptions?: NavButtonOptionsRecord;    // all vertical dropdowns

    itemClicked?: (itemCaption: string, dropdownCaption: string | null) => void;
    isVisibleCallback?: (item: MenuItemData) => boolean;
}
interface PopupRecord {
    mainIndex: number;      // valid on popup1 and popup2
    subIndex: number;       // === -1 if this not popup2
    left: number;
    top: number
}
const NavBar: React.FC<NavBarProps> = (props) => {
    const [popup1, setPopup1] = React.useState<PopupRecord>();  //   { index, left, top }
    const [popup2, setPopup2] = React.useState<PopupRecord>();  //   { indexes, left, top }
    const [rollOff, setRollOff] = React.useState<ButtonIndexesRecord>();
    const [rollOffTimerElapsed, setRollOffTimerElapsed] = React.useState(false);
    const [rollOn, setRollOn] = React.useState<ButtonIndexesRecord>();

    const navigate = useNavigate();
    const { getContext } = useGlobalContext();

    const fontFamily = props.fontFamily ? props.fontFamily : app.themes.navbarFont;

    const mergeFields = (source: Record<string, any>, target: Record<string, any>) => {
        for (const field in source) {
            target[field] = source[field];
        }
    }
    const mainOptions = {
        height: 48,
        foreColor: app.themes.backColor10,
        backColor: app.themes.foreColor,
        backgroundImage: props.mainOptions?.backgroundImage,
        hoverForeColor: app.themes.foreColor,
        hoverBackColor: app.themes.backColor10,
        padding: 16,
        fontWeight: "bold",
        fontSize: 24
    };
    if (props.mainOptions) {
        mergeFields(props.mainOptions as Record<string, any>, mainOptions as Record<string, any>);
    }
    if (window.matchMedia("(max-width: " + smallNavFontWidth + "px)").matches) {
        mainOptions.fontSize = 14;
        mainOptions.padding = 8;
    }
    const dropdownOptions = {
        height: mainOptions.height * 0.8,
        foreColor: app.themes.foreColor,
        backColor: app.themes.backColor25,
        hoverForeColor: app.themes.backColor25,
        hoverBackColor: app.themes.foreColor,
        padding: mainOptions.padding * 0.8,
        fontSize: mainOptions.fontSize * 0.8
    };
    if (props.dropdownOptions) {
        mergeFields(props.dropdownOptions as Record<string, any>, dropdownOptions as Record<string, any>);
    }

    // console.log("mainOptions:", mainOptions)

    React.useEffect(() => {
        if (rollOffTimerElapsed) {
            // we have rolled off a menu item; see if we are onto a new one; if not close the popup
            closePopupsExcept(-1);      // close all
            setRollOff(undefined);
            setRollOffTimerElapsed(false);
        }
    }, [rollOffTimerElapsed]);
    React.useEffect(() => {
        if (rollOn) {
            const { target, mainIndex, sub1Index, sub2Index } = rollOn;
            let popup1State = popup1;
            if (rollOff) {
                //  console.log("rolling off of:", rollOff);
                // mouse has left a button within the last half second; if this is from the same index keep it open
                if (rollOff.sub1Index === -1 || rollOff.sub1Index !== sub1Index) {
                    setPopup2(undefined);
                }
                if (rollOff.mainIndex !== mainIndex) {
                    setPopup1(undefined);
                    popup1State = undefined;
                }
                //   console.log("clearing " + rollOff.timerId)
                clearTimeout(rollOff.timerId!);
                setRollOff(undefined);
            }
            closePopupsExcept(mainIndex);       // close other popups if they are open
            if (props.menuItems[mainIndex].popupItems && props.menuItems[mainIndex].popupItems!.length) {
                // showPopup(mainIndex);
                // if (sub1Index !== -1) {
                //     showPopup(mainIndex, sub1Index);
                // }
                // show first popup if not already open
                if (!popup1State || popup1State.mainIndex !== mainIndex) {
                    showPopup(mainIndex);
                }
                if (sub1Index !== -1 &&
                    props.menuItems[mainIndex].popupItems![sub1Index].popupItems && props.menuItems[mainIndex].popupItems![sub1Index].popupItems!.length) {
                    // show popup2
                    showPopup(mainIndex, sub1Index);
                }
            }
        }
    }, [rollOn]);

    const closePopupsExcept = (except: number) => {
        if (popup1 && popup1.mainIndex !== except) {
            setPopup1(undefined);
        }
        if (popup2 && popup2.mainIndex !== except) {
            setPopup2(undefined);
        }
    }

    // caller can create canvas and pass it for repeated calls
    const measureText = (text: string, font: string, passedCanvas?: HTMLCanvasElement): number => {
        //    console.log("measureText(" + text + ", " + font);
        // re-use canvas object for better performance
        const canvas = passedCanvas ? passedCanvas : document.createElement("canvas");
        const context = canvas.getContext("2d");
        context!.font = font;
        const metrics = context!.measureText(text);
        return metrics.width + 4;
    }
    const measureDropdownWidth = (popupItems: MenuItemData[]) => {
        let width = 0;
        popupItems.forEach(item => {
            const itemWidth = measureText(item.caption, dropdownOptions.fontSize + "px " + fontFamily) + 48;
            width = Math.max(width, itemWidth);
        });
        return width;
    }
    // pass subIndex -1 if this is off the main menu; else it is off a sub (popup) menu
    const showPopup = (mainIndex: number, subIndex: number = -1) => {
        // find the nav button that is being hovered over
        const navButton = document.getElementById(formatNavButtonId(mainIndex, subIndex));
        const rect = navButton!.getBoundingClientRect();
        let left = rect.x;
        let top = rect.y;
        if (subIndex === -1) {
            top += mainOptions.height - 12;      // opening first popup menu so place below button on horizontal bar
            const dropdownWidth = measureDropdownWidth(props.menuItems[mainIndex].popupItems!);
            if (left > window.innerWidth - dropdownWidth) {
                left = window.innerWidth - dropdownWidth - 8;
            }
            setPopup1({ mainIndex, subIndex: -1, left, top });
        } else {
            left += rect.width + 4;         // opening submenu so place to right of button on vertical popup
            const dropdownWidth = measureDropdownWidth(props.menuItems[mainIndex].popupItems![subIndex].popupItems!);
            if (left > window.innerWidth - dropdownWidth) {
                left = rect.x - dropdownWidth;
            }
            setPopup2({ mainIndex, subIndex, left, top });
        }
    }
    const mouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        const indexes = parseIndexes(e.target as HTMLDivElement);
        //   console.log("mouseEnter:", indexes, "; rollOff:", rollOff);
        if (rollOff && rollOff.mainIndex !== indexes.mainIndex) {
            setRollOff(undefined);
            clearTimeout(rollOff.timerId!);
        }
        setRollOn(indexes);
    }
    const mouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        const indexes = parseIndexes(e.target as HTMLDivElement);
        const { target, mainIndex, sub1Index, sub2Index } = indexes;
        //    console.log("leaving:", indexes)
        indexes.timerId = setTimeout(() => setRollOffTimerElapsed(true), 500);
        setRollOff(indexes);
    }

    const handleNavClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const { target, mainIndex, sub1Index, sub2Index } = parseIndexes(e.target as HTMLDivElement);
        //  console.log("indexes:", { target, mainIndex, sub1Index, sub2Index })
        let href;
        if (sub1Index === -1) {
            // main menu item clicked
            href = props.menuItems[mainIndex].href;
        } else if (sub2Index === -1) {
            // dropdown item clicked
            href = props.menuItems[mainIndex].popupItems![sub1Index].href;
        } else {
            href = props.menuItems[mainIndex].popupItems![sub1Index].popupItems![sub2Index].href;
        }
        if (!href) {
            if (props.menuItems[mainIndex].popupItems) {
                // open popup menu (works on devices that don't allow mouse hover)
                mouseEnter(e);
            } else if (props.itemClicked) {
                props.itemClicked(
                    props.menuItems[mainIndex].caption,
                    sub1Index >= 0 ? props.menuItems[mainIndex].popupItems![sub1Index].caption : null
                );
            }
        } else {
            closePopupsExcept(-1);      // close all
            if (href.startsWith("http")) {
                window.open(href, '_blank');
                // window.location.href = href;
            } else {
                navigate(href);
            }
        }
    }

    if (getContext(hideNavBarKey)) {
        return null;
    }
    return (
        <MasterContainer $fontFamily={fontFamily} $fontWeight={mainOptions.fontWeight}>
            <MainMenuContainer $backColor={mainOptions.backColor} $backgroundImage={mainOptions.backgroundImage} $height={mainOptions.height}>
                <MenuButtonContainer $isVertical={false} $backColor={mainOptions.backColor} $foreColor={mainOptions.foreColor}
                    $hoverForeColor={mainOptions.hoverForeColor} $hoverBackColor={mainOptions.hoverBackColor}
                    $fontSize={mainOptions.fontSize}>
                    {props.menuItems.map((item, index) => {
                        return (!props.isVisibleCallback || props.isVisibleCallback(item)) &&
                            <div key={item.caption} id={formatNavButtonId(index)}
                                onClick={handleNavClick} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                                {item.caption}
                            </div>
                    })}
                </MenuButtonContainer>
            </MainMenuContainer>
            {popup1 &&
                <MenuButtonContainer $isVertical={true} $backColor={dropdownOptions.backColor} $foreColor={dropdownOptions.foreColor}
                    $hoverForeColor={dropdownOptions.hoverForeColor} $hoverBackColor={dropdownOptions.hoverBackColor}
                    $fontSize={dropdownOptions.fontSize} $top={popup1.top} $left={popup1.left}>
                    {props.menuItems[popup1.mainIndex].popupItems!.map((item, index) => {
                        //    console.log(item.caption + ": popup1:", popup1)
                        return (!props.isVisibleCallback || props.isVisibleCallback(item)) &&
                            <DropdownButton key={item.caption} id={formatNavButtonId(popup1.mainIndex, index)}
                                onClick={handleNavClick} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                                <span>{item.caption}</span>
                                {props.menuItems[popup1.mainIndex].popupItems![index].popupItems && props.menuItems[popup1.mainIndex].popupItems![index].popupItems!.length &&
                                    <span>&nbsp;&gt;&nbsp;</span>
                                }
                            </DropdownButton>
                    })}
                </MenuButtonContainer>
            }
            {popup2 &&
                <MenuButtonContainer $isVertical={true} $backColor={dropdownOptions.backColor} $foreColor={dropdownOptions.foreColor}
                    $hoverForeColor={dropdownOptions.hoverForeColor} $hoverBackColor={dropdownOptions.hoverBackColor}
                    $fontSize={dropdownOptions.fontSize} $top={popup2.top} $left={popup2.left}>
                    {props.menuItems[popup2.mainIndex].popupItems![popup2.subIndex!].popupItems!.map((item, index) => {
                        return (!props.isVisibleCallback || props.isVisibleCallback(item)) &&
                            <DropdownButton key={item.caption} id={formatNavButtonId(popup2.mainIndex, popup2.subIndex!, index)}
                                onClick={handleNavClick} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                                <span>{item.caption}</span>
                            </DropdownButton>
                    })}
                </MenuButtonContainer>
            }
        </MasterContainer >
    );
}

//---------------------------------------------------------------
// NOTE: app.css MUST INCLUDE slidein and slideout FOR THIS TO WORK!!!
const menuItemLineHeight = 20;
const menuItemPadding = 7;
const SlidingMasterContainer = styled.div`
    position: relative;
`
const MenuSlider = styled.div<{ $zIndex: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 100vw;
    z-index: ${props => props.$zIndex};
    width: 75vw;
    border: 1px solid;
    position: fixed;
    margin: 0;
    padding: 0;
`
const SliderContainer = styled.div<{ $fontFamily: string; $backColor: string; $hoverForeColor: string; $hoverBackColor: string }>`
    font: 18px ${props => props.$fontFamily};
    background-color: ${props => props.$backColor};
    div: hover {
        color: ${props => props.$hoverForeColor};
        background-color: ${props => props.$hoverBackColor};
    }
`
const MenuItem = styled.div<{ $fontWeight: string; $justify: string; $foreColor: string; $backColor: string }>`
    display: flex;
    justify-content: ${props => props.$justify};     /* space-between or flex-start */
    align-items: center;
    font-weight: ${props => props.$fontWeight};
    color: ${props => props.$foreColor};
    background-color: ${props => props.$backColor};
    cursor: pointer;
    border-bottom: 1px solid;
    line-height: ${menuItemLineHeight}px;
    padding: ${menuItemPadding}px;
`
interface NavColorsRecord {
    backColor: string;
    foreColor: string;
    hoverBackColor: string;
    hoverForeColor: string;
    dropdownBackColor: string;
    dropdownForeColor: string;
}
interface SlidingNavBarProps extends NavBarProps {
    socialMedia?: React.FC<{ justify?: string; backColor?: string; padding: number; lineHeight: number }>;
    vouchersLink?: string;
    colors?: NavColorsRecord;
}
export const SlidingNavBar: React.FC<SlidingNavBarProps> = (props) => {
    const [menuTop, setMenuTop] = React.useState<number>();
    const [menusActive, setMenusActive] = React.useState(false);
    const [mainMenuOpen, setMainMenuOpen] = React.useState(false);
    const [subMenuOpen, setSubMenuOpen] = React.useState(-1);     // index of dropdown menu that is open
    const [subPopupOpen, setSubPopupOpen] = React.useState(-1);

    const navbarRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const subMenuRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const navigate = useNavigate();

    const colors = props.colors ? props.colors : {
        backColor: app.themes.backColor10,
        foreColor: app.themes.foreColor,
        hoverBackColor: app.themes.foreColor,
        hoverForeColor: app.themes.backColor10,
        dropdownBackColor: app.themes.backColor10,
        dropdownForeColor: app.themes.foreColor
    };

    React.useEffect(() => {
        const submenu = subMenuRef.current! as HTMLDivElement;
        if (submenu && subMenuOpen >= 0) {
            submenu.classList.add("slideIn");
            submenu.onanimationend = () => {
                submenu.classList.remove("slideIn");
                submenu.style.left = "25vw";
                navbarRef.current!.style.opacity = "0";
            };
        }
    }, [subMenuOpen]);

    React.useEffect(() => {
        if (mainMenuOpen) {
            const navbar = navbarRef.current as HTMLDivElement;
            navbar.style.opacity = "1";
            const className = mainMenuOpen ? "slideIn" : "slideOut";
            navbar.classList.add(className);
            navbar.onanimationend = () => {
                navbar.classList.remove(className);
                navbar.style.left = mainMenuOpen ? "25vw" : "100vw";
            };
        }
    }, [mainMenuOpen]);

    const showMenuClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const top = target.getBoundingClientRect().y + 30;
        setMenuTop(top);
        setMainMenuOpen(!mainMenuOpen);
        setMenusActive(!menusActive);
    }
    const menuItemClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const { target, mainIndex, sub1Index, sub2Index } = parseIndexes(e.target as HTMLDivElement);
    //    console.log("indexes:", { target, mainIndex, sub1Index, sub2Index })
        let href;
        if (props.menuItems[mainIndex].popupItems) {
            setSubMenuOpen(mainIndex);
        } else {
            href = props.menuItems[mainIndex].href;
        }
        if (href) {
            setMainMenuOpen(false);
            setMenusActive(false);
            if (href.startsWith("http")) {
                window.open(href, '_blank');
            } else {
                navigate(href);
            }
        } else if (props.itemClicked) {
            props.itemClicked(
                props.menuItems[mainIndex].caption,
                sub1Index >= 0 ? props.menuItems[mainIndex].popupItems![sub1Index].caption : null
            );
        }
    }

    const submenuItemClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const indexes = parseIndexes(e.target as HTMLDivElement);
        const { target, mainIndex, sub1Index, sub2Index } = indexes;
        // see if it has another level; if so toggle the view of that
        if (sub2Index === -1 && props.menuItems[mainIndex].popupItems![sub1Index].popupItems && props.menuItems[mainIndex].popupItems![sub1Index].popupItems!.length) {
            if (subPopupOpen === sub1Index) {
                // already open so toggle closed
                setSubPopupOpen(-1);
            } else {
                setSubPopupOpen(sub1Index);
            }
        } else {
            const menuItem = sub2Index === -1 ? props.menuItems[mainIndex].popupItems![sub1Index] : props.menuItems[mainIndex].popupItems![sub1Index].popupItems![sub2Index];
            const href = menuItem.href as string;
            setMainMenuOpen(false);
            setSubMenuOpen(-1);
            setMenusActive(false);
            if (href) {
                if (href.startsWith("http")) {
                    window.open(href, '_blank');
                } else {
                    navigate(href);
                }
            }
        }
    }
    const closeSubMenuClicked = () => {
        navbarRef.current!.style.opacity = "1";
        const submenu = subMenuRef.current! as HTMLDivElement;
        submenu.classList.add("slideOut");
        submenu.onanimationend = () => {
            setSubMenuOpen(-1);
        };
    }
    return (
        <SlidingMasterContainer>
            <i style={{ fontSize: "24px", marginLeft: "8px" }} className="fas fa-bars" onClick={showMenuClicked} />
            {menusActive &&
                <React.Fragment>
                    <MenuSlider ref={navbarRef} $zIndex={5000}>
                        <SliderContainer $fontFamily={app.themes.sansFonts} $backColor={colors.backColor} $hoverForeColor={colors.hoverForeColor} $hoverBackColor={colors.hoverBackColor}>
                            <React.Fragment>
                                {props.menuItems.map((item, menuIndex) => {
                                    return (!props.isVisibleCallback || props.isVisibleCallback(item)) &&
                                        (
                                            <React.Fragment key={item.caption}>
                                                <MenuItem $fontWeight="bold" id={formatNavButtonId(menuIndex)} $foreColor={colors.foreColor} $backColor={colors.backColor}
                                                    $justify="space-between"
                                                    onClick={menuItemClicked}>
                                                    <span>{item.caption}</span>
                                                    {item.popupItems && <i style={{ marginRight: "16px" }} className="fas fa-chevron-right"></i>}
                                                </MenuItem>
                                            </React.Fragment>
                                        )
                                })}
                                {props.vouchersLink &&
                                    <MenuItem $fontWeight="regular" $foreColor={colors.foreColor} $backColor={colors.backColor} $justify="space-between" onClick={() => navigate(props.vouchersLink!)}>
                                        <span>Gift Certificates...</span>
                                    </MenuItem>
                                }
                            </React.Fragment>
                        </SliderContainer>
                        {props.socialMedia &&
                            <props.socialMedia justify="center" backColor={colors.backColor} lineHeight={menuItemLineHeight} padding={menuItemPadding} />
                        }
                    </MenuSlider>
                    {subMenuOpen >= 0 &&
                        <MenuSlider ref={subMenuRef} $zIndex={6000}>
                            <SliderContainer $fontFamily={app.themes.sansFonts} $backColor={colors.backColor} $hoverForeColor={colors.hoverForeColor} $hoverBackColor={colors.hoverBackColor}>
                                <MenuItem $fontWeight="bold" $foreColor={colors.foreColor} $backColor={colors.backColor} $justify="flex-start" onClick={closeSubMenuClicked}>
                                    <i className="fas fa-chevron-left"></i>
                                    <span style={{ marginLeft: "16px" }}>{props.menuItems[subMenuOpen].caption}</span>
                                </MenuItem>
                                <ListDropdownItems dropdownItems={props.menuItems[subMenuOpen].popupItems!} keyPrefix='' subPopupOpen={subPopupOpen}
                                    indentLevel={0} mainIndex={subMenuOpen} subMenuIndex={-1}
                                    foreColor={colors.dropdownForeColor} backColor={colors.dropdownBackColor} itemClicked={submenuItemClicked} />
                            </SliderContainer>
                        </MenuSlider>
                    }
                </React.Fragment>
            }
        </SlidingMasterContainer>
    )
}
interface ListDropdownItemsProps {
    dropdownItems: MenuItemData[];
    mainIndex: number;
    subMenuIndex: number;
    subPopupOpen: number;       // -1 if none open
    keyPrefix: string;
    indentLevel: number;        // 0 or 1
    foreColor: string;
    backColor: string;
    itemClicked: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const ListDropdownItems: React.FC<ListDropdownItemsProps> = (props) => {
    return (
        <React.Fragment>
            {props.dropdownItems.map((popup, popupIndex) => {
                const hasDropdown = popup.popupItems && popup.popupItems!.length;
                return (
                    <>
                        <MenuItem key={props.keyPrefix + popupIndex} id={formatNavButtonId(props.mainIndex, props.subMenuIndex, popupIndex)}
                            $foreColor={props.foreColor} $backColor={props.backColor}
                            $fontWeight="regular" $justify="flex-start" onClick={props.itemClicked}>
                            {props.indentLevel > 0 && <span>&nbsp;&nbsp;&nbsp;</span>}
                            <span>{popup.caption}</span>
                            {hasDropdown && <><span>&nbsp;</span><span>&#9660;</span></>}
                        </MenuItem>
                        {props.subPopupOpen === popupIndex &&
                            <ListDropdownItems dropdownItems={popup.popupItems!} keyPrefix="s" subPopupOpen={-1} indentLevel={1} subMenuIndex={popupIndex}
                                mainIndex={props.mainIndex} foreColor={props.foreColor} backColor={props.backColor} itemClicked={props.itemClicked} />
                        }
                    </>
                )
            })
            }
        </React.Fragment>
    )
}
export default NavBar;
