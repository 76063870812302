import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { useFetchApi, genericApiError } from '../libs/useDataApiV2';
import MasterPage from './MasterPage';
import '../App.css';
import { StyledErrorText } from '../libs/libSupport';
import Spinner from '../libs/Spinner';

import { WebsiteRecordContentRecord, WebsiteTagRecord } from '../interfaces/lib-websites-interfaces';

import app from '../appData';
import api from '../api-url';

/* api returns the following:
    [
        public string company { get; set; }
        public string city { get; set; }
        public string phone { get; set; }
        public string url { get; set; }
    ]
*/
const StyledTable = styled.table`
    margin-top: 8px;
    border: 2px solid;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    border-collapse: collapse;
    a:link {
        text-decoration: underline !important;
    }
`
const StyledCaption = styled.tr<{ backColor: string }>`
    font-weight: bold;
    font-size: 16px;
    background-color: ${props => props.backColor};
`
const StyledRow = styled.tr<{ backColor: string | null }>`
    background-color: ${props => props.backColor};
`
const StyledCell = styled.td`
    border: 1px solid;
    padding: 4px;
`
const StyledMessageText = styled.p`
    font-size: 14px;
    text-align: center;
    width: 100%;
`
interface StoreRecord {
    company: string;
    address: string;
    city_state_zip: string;
    url: string;
}
const Stores: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [records, setRecords] = React.useState<StoreRecord[]>();
    const { fetch, isFetchLoading } = useFetchApi();
    const location = useLocation();

    const isDashboard = location.search.includes("dashboard");

    const gotData = ((data: WebsiteTagRecord[]) => {
        const recordContent = data[0].content as WebsiteRecordContentRecord;
        setRecords(recordContent.content as StoreRecord[]);
    })

    React.useEffect(() => {
        fetch(api.fetchContent + "stores/" + (isDashboard ? 1 : 0), null, gotData, () => setErrorMsg(genericApiError));
    }, []);

    return (
        <MasterPage>
            {records &&
                <React.Fragment>
                    <h1>Where to Find Laurainbo Crystals</h1>
                    {isFetchLoading() && <Spinner />}
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    <StyledTable>
                        <tbody>
                            <StyledCaption backColor={app.themes.backColor50}>
                                <StyledCell>Store name</StyledCell>
                                <StyledCell>Address</StyledCell>
                                <StyledCell>City</StyledCell>
                            </StyledCaption>
                            {records.map((store, index) => {
                                return (
                                    <StyledRow key={index} backColor={index % 2 ? app.themes.backColor25 : null}>
                                        {store.url ? (
                                            <StyledCell><a target="_blank" rel="noopener noreferrer" href={store.url}>{store.company}</a></StyledCell>
                                        ) : (
                                            <StyledCell>{store.company}</StyledCell>
                                        )}
                                        <StyledCell>{store.address}</StyledCell>
                                        <StyledCell>{store.city_state_zip}</StyledCell>
                                    </StyledRow>
                                );
                            })}
                        </tbody>
                    </StyledTable>
                </React.Fragment>
            }
        </MasterPage>
    )
}

export default Stores;
