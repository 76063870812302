import React from 'react'
import styled from 'styled-components';

import { SliderRecord } from "../interfaces/lib-react-interfaces";

import "../App.css";

const showAllMode = false;

/* the following MUST be in App.css:
.fade-in-class {
  animation-name: fade-in;
  animation-duration: 1s;
}
.fade-out-class {
  animation-name: fade-out;
  animation-duration: 1s;
}
@keyframes fade-in {
  from { opacity: 0.0; }
  to   { opacity: 1.0; }
}

@keyframes fade-out {
  from { opacity: 1.0; }
  to   { opacity: 0.0; }
  }
*/

const MasterContainer = styled.div<{ $height: number; }>`
    text-align: left;
    width: 100%;
    position: relative;
    height: ${props => props.$height}px;

    img {
        position: absolute;
        margin-left: 50%;
        transform: translate(-50%);
        height: ${props => props.$height}px;
        width: auto;
    }

`
interface SliderProps {
    images: SliderRecord[];
    delay: number;
    height: number;
}
const Slider: React.FC<SliderProps> = (props) => {
    const [transCount, setTransCount] = React.useState(0);

    const stageRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        if (!showAllMode) {
            if (transCount) {
                nextImage();
            }
            setTimeout(() => setTransCount(transCount + 1), props.delay);
        }
    }, [transCount]);

    const nextIndex = (currIndex: number): number => {
        return (currIndex === props.images.length - 1 ? 0 : currIndex + 1);
    }

    const nextImage = () => {
        const images = stageRef.current.getElementsByTagName("img");
        for (let i = 1; i < images.length; i++) {
            images[i].remove();
        }
        const currImg = images[0];
        currImg.className = "fade-out-class";
        currImg.addEventListener("animationend", () => {
            currImg.remove();
        }, false);
        const img = document.createElement("img");
        img.setAttribute("src", props.images[nextIndex(index)].src);
        img.className = "fade-in-class";
        stageRef.current.appendChild(img);
        setIndex(nextIndex(index));
    }

    return (
        <>
            {showAllMode ? (
                <div>
                    {props.images.map(image => {
                        return (
                            <img src={image.src} />
                        )
                    })}

                </div>
            ) : (
                <MasterContainer ref={stageRef} $height={props.height}>
                    <img src={props.images[0].src} />
                </MasterContainer>
            )}
        </>
    )
}
export default Slider;
