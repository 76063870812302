import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import MasterPage from './MasterPage';
import { desktopWidth, iPhoneWidth, StyledErrorText } from '../libs/libSupport';
import { useFetchApi, genericApiError } from '../libs/useDataApiV2';
import Spinner from '../libs/Spinner';
import { formatImageUrl, formatVideoIframeSrc } from '../libs/ImageFormatter';

import { ImageEditorOptions, ImageFileOptions, ImageRecord } from '../interfaces/lib-api-interfaces';
import { WebsitePhotoContentType, WebsiteTagRecord } from '../interfaces/lib-websites-interfaces';

import app from '../appData';
import api from '../api-url';

const MasterContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: ${desktopWidth}px;
    a:link { text-decoration: underline; }
`
const ImageContainer = styled.img<{ maxWidth: number; imageBorder: string }>`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: ${props => props.maxWidth}px;
    border: ${props => props.imageBorder};
    height: auto;
    @media (max-width: ${iPhoneWidth}px) {
        width: 90%;
    }
`
const CaptionText = styled.p<{ maxWidth: number }>`
    max-width: ${props => props.maxWidth}px;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    margin-top: 4px;
    margin-bottom: 24px;
    @media (max-width: ${iPhoneWidth}px) {
        width: 90%;
    }
`
const VideoContainer = styled.div<{ imageBorder: string }>`
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    width: 560px;
    border: ${props => props.imageBorder};
`

const Press: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [content, setContent] = React.useState<ImageRecord[]>();
    const [imageOptions, setImageOptions] = React.useState<ImageEditorOptions>();

    const { fetch, isFetchLoading } = useFetchApi();
    const location = useLocation();

    const isDashboard = location.search.includes("dashboard");

    const gotData = (data: WebsiteTagRecord[]) => {
        const photoRecord = data[0].content as WebsitePhotoContentType;
        setContent(photoRecord.images);
        setImageOptions(photoRecord.imageOptions);
    }

    React.useEffect(() => {
        if (!content) {
            fetch(api.fetchContent + "press/" + (isDashboard ? 1 : 0), null, gotData, () => setErrorMsg(genericApiError));
        }
    }, []);

    return (
        <MasterPage>
            {content && imageOptions &&
                <MasterContainer>
                    <h1>Press</h1>
                    {isFetchLoading() && <Spinner />}
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    {content.map(clipping => {
                        return (
                            clipping.stream_id ? (
                                <PressPostVideo key={clipping.stream_id} width={clipping.size ?? imageOptions.fileOptions!.size!} clipping={clipping}
                                    imageOptions={imageOptions.fileOptions!} />
                            ) : (
                                <PressPostPhoto key={clipping.filename} maxWidth={clipping.size ?? imageOptions.fileOptions!.size!} url={clipping.filename!}
                                    subFolder={imageOptions.fileOptions?.graphicsSubfolder} caption={clipping.caption!} alt={clipping.alt} />
                            )
                        )
                    })}
                </MasterContainer>
            }
        </MasterPage>
    )
}
interface PressPostPhotoProps {
    maxWidth: number;
    url: string;
    alt?: string;
    caption: string;
    subFolder?: string;
}
const PressPostPhoto: React.FC<PressPostPhotoProps> = (props) => {
    return (
        <React.Fragment>
            <ImageContainer maxWidth={props.maxWidth} imageBorder={app.themes.imageBorder}
                src={formatImageUrl(props.url, { graphicsSubfolder: "other" })}
                alt={props.alt ? props.alt : props.caption} />
            <CaptionText maxWidth={props.maxWidth} dangerouslySetInnerHTML={{ __html: props.caption }} />
        </React.Fragment>
    )
}
interface PressPostVideoProps {
    width: number;
    clipping: ImageRecord;
    imageOptions: ImageFileOptions;
}
const PressPostVideo: React.FC<PressPostVideoProps> = (props) => {
    return (
        <React.Fragment>
            <VideoContainer imageBorder={app.themes.imageBorder}>
                <iframe style={{ display: "block" }} width={props.width} height={props.width / 1.78} src={formatVideoIframeSrc(props.clipping.stream_source!, props.clipping.stream_id!)}
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </VideoContainer>
            {props.clipping.caption &&
                <CaptionText maxWidth={props.width} dangerouslySetInnerHTML={{ __html: props.clipping.caption }} />
            }
        </React.Fragment>
    )
}

export default Press;
