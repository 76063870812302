import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import MasterPage from './MasterPage';
//import Slider from '../libs/Spring-slider';
import Slider from '../libs/SliderV4';
import '../App.css';
import { StyledErrorText } from '../libs/libSupport';
import { useFetchApi, genericApiError } from '../libs/useDataApiV2';
import Spinner from '../libs/Spinner';

import { SliderRecord } from '../interfaces/lib-react-interfaces';

import { ImageRecord } from '../interfaces/lib-api-interfaces';
import { WebsitePhotoContentType, WebsiteTagRecord } from '../interfaces/lib-websites-interfaces';

import api from '../api-url';
import app from '../appData';
import { formatImageUrl } from '../libs/ImageFormatter';

const FacebookText = styled.p`
    font-size: 18px;
    text-align: center;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
    a:link { 
        text-decoration: underline;
    }
`
interface ApiPhotoRecord {
    filename: string;
}
const Gallery: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [showSpinner, setShowSpinner] = React.useState(false);
    const { fetch } = useFetchApi();
    const [content, setContent] = React.useState<SliderRecord[]>();
    const location = useLocation();

    const isDashboard = location.search.includes("dashboard");

    const gotData = (data: WebsiteTagRecord[]) => {
        const images: SliderRecord[] = [];
        const photoContent = data[0].content as WebsitePhotoContentType;
        (photoContent.images as ImageRecord[]).forEach((image, index) => {
            images.push({ src: formatImageUrl(image.filename!, { graphicsSubfolder: photoContent.imageOptions.fileOptions?.graphicsSubfolder }) });
        });
        setContent(images);
        setShowSpinner(false);
    }
    React.useEffect(() => {
        // const images = [] as SliderRecord[];
        // for (let i = 1; i <= 6; i++) {
        //     images.push({ src: formatImageUrl(i + ".jpg", { graphicsSubfolder: "numbers" })});
        // }
        // setContent(images);
        fetch(api.fetchContent + "photo-gallery/" + (isDashboard ? 1 : 0), null, gotData, () => setErrorMsg(genericApiError));
        setShowSpinner(true);
    }, []);

    return (
        <MasterPage>
            {showSpinner && <Spinner />}
            {content &&
                <React.Fragment>
                    <h1>Photo Gallery</h1>
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    <Slider height={600} images={content} delay={6000} />
                    <FacebookText>
                        These are just a sampling of the hundreds of photos from Laurainbo's collection. To see more, visit  <a href="https://www.facebook.com/laurainbocrystals/">Laurainbo Crystals' Facebook page.</a>
                    </FacebookText>
                </React.Fragment>
            }
        </MasterPage>
    )
}
export default Gallery;

//                     {false && <Slider height={600} border={app.themes.imageBorder} images={content} delay={3000} />}

