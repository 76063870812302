import Cookie from 'universal-cookie';
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'

import { TokenRecord } from "../interfaces/lib-api-interfaces";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

// #region useGlobalContext
export const useGlobalContext = () => {
    const dispatch = useDispatch();
    const globalState = useSelector((state: RootState) => state.stateMgr.value);
    const getContext = (key: string) => {
        try {
            // console.log("state:", useSelector((state: RootState) => state));

            // const value = useSelector((state: RootState) => state.stateMgr.value[key]);
            const value = globalState[key];
            return value;
        } catch (err) {
            console.log(err)
        }
    }
    const setContext = (key: string, data: any) => {
        dispatch(update({ key, data }));
    }
    const removeContext = (key: string) => {
        dispatch(remove({ key }));
    }
    return { getContext, setContext, removeContext };
}

type GlobalState = Record<string, any>

interface ActionRecord {
    key: string;
    data?: any;
}

const initialState: GlobalState = {
    value: {},
}

const stateMgrSlice = createSlice({
    name: 'stateMgr',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<ActionRecord>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            const { key, data } = action.payload;
            state.value[key] = data;
        },
        remove: (state, action: PayloadAction<ActionRecord>) => {
            const { key } = action.payload;
            delete state.value[key];
        }
    },
})

export const store = configureStore({
    reducer: {
        stateMgr: stateMgrSlice.reducer,
    },
})

// Action creators are generated for each case reducer function
const { update, remove } = stateMgrSlice.actions

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>
// #endregion useGlobalContext


export const useSessionStore = () => {
    // following transfers session storage to component storage; can also be used to fetch if not in session storage
    // key : string, data : object, setData : callback to set component storage, fetchInfo { url : string, token : optional string, isFetchLoading and fetch are callbacks into useDataApi }
    // if setData is omitted the data is only returned; setData is required if fetch info given
    // data can be passed as shortcut to placing it in component storage; if not null and setData given it will be set
    // token will be defaulted if not passed
    const { setContext } = useGlobalContext();

    const getSessionStore = (key: string): any => {
        const rawData: string | null = sessionStorage.getItem(key);
        return rawData ? JSON.parse(rawData) : null;
    }
    const sessionStoreContains = (key: string) => {
        return (key in sessionStorage);
    }
    const setSessionStore = (key: string, data: any, doNotRerender?: boolean) => {
        const currStringified = sessionStorage.getItem(key);
        const newstringified = JSON.stringify(data);
        if (currStringified !== null && currStringified === newstringified) {
            return;
        }
        sessionStorage.setItem(key, newstringified);
        if (!doNotRerender) {
            setContext("forceRerender", Date.now());
        }
    }
    const clearSessionStore = () => {
        sessionStorage.clear();
    }
    const deleteSessionStore = (key: string) => {
        sessionStorage.removeItem(key);
    }
    return { getSessionStore, setSessionStore, clearSessionStore, deleteSessionStore, sessionStoreContains }
}

export const useCookies = () => {
    const areCookiesEnabled = () => {
        let cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled) {
            document.cookie = "testcookie";
            cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
        }
        return cookieEnabled;
    }
    // pass expDays 0 for maximum
    const setCookie = (key: string, value: any, expDays: number) => {
        const cookie = new Cookie();
        cookie.set(key, value, { domain: window.location.hostname, path: "/", expires: dayjs().add(expDays === 0 ? 10000 : expDays, 'day').toDate() });
    }
    const getCookie = (key: string) => {
        const cookie = new Cookie();
        return cookie.get(key);
    }
    const removeCookie = (key: string) => {
        const cookie = new Cookie();
        cookie.remove(key, { path: "/", domain: window.location.hostname });
    }
    return { areCookiesEnabled, getCookie, setCookie, removeCookie };
}

export const useBookmarks = () => {
    const { getSessionStore, setSessionStore } = useSessionStore();
    const navigate = useNavigate();

    // pass url null to use current url (always use window.location.pathname)
    const setBookmark = (url?: string) => {
        setSessionStore("bookmark", url ? url : window.location.pathname);
    }
    const navigateToBookmark = () => {
        let url = getSessionStore("bookmark");
        setSessionStore("bookmark", null);
        if (url === "/login" || url === "/register")
            url = "/";
        navigate(url ?? "/");
    }
    return { setBookmark, navigateToBookmark };
}

export const useTokens = () => {
    const { getCookie, setCookie, removeCookie } = useCookies();
    const { getSessionStore, setSessionStore, deleteSessionStore } = useSessionStore();
    const { setContext } = useGlobalContext();

    // pass persist=true to store in cookie, else store in session state
    const setToken = (token: TokenRecord, persist = true) => {
        if (persist) {
            setCookie("token", token, 30);
        } else {
            setSessionStore("token", token);
        }
    }
    // check session store first, then cookie (token will be in cookie if user checked "remember me")
    const getToken = (): TokenRecord | null => {
        let token = getSessionStore("token");
        if (token) {
            return token;
        }
        return getCookie("token");
    }
    const clearToken = () => {
        removeCookie("token");
        deleteSessionStore("token");
        setContext("rerender", Date.now());
    }
    return { setToken, getToken, clearToken };
}

export const addDays = (date: Date, days: number) => {
    return new Date(date.setDate(date.getDate() + days));
}

