import React from 'react';

import { MenuItemData } from './interfaces/lib-api-interfaces';

export const isDashboard = false;

const domain = "laurainbocrystals.com";

export const productGraphicsBaseUrl = (window.location.hostname === "localhost" ? process.env.PUBLIC_URL : "https://" + domain) + "/graphics";

const bannerBgImageAsSolid = "#29228f";
const palatino = "'Palatino Linotype', 'EB Garamond', 'serif'";
const sansFonts = "Arial,Helvetica,sans-serif";
export const themes = {
    defaultFonts: palatino,
    sansFonts: palatino,
    h1FontFamily: palatino,
    navbarFont: palatino,
    bannerFont: palatino,
    bannerBackgroundImage: "other/banner_bg.jpg",
    bannerBgImageAsSolid,
    imageBorder: "solid 14px " + bannerBgImageAsSolid,
    pageBackgroundImage: "other/cloud_bg2.jpg",
    foreColor: "#29228f",
    backColor25: "#d4d3e9",     // forecolor at 20%
    backColor50: "#A9A7D2",     // forecolor at 40%
    backColor10: "#d4d3e9",   // same as 25 for now
    modalStyles: { fontSize: "16px", fontFamily: sansFonts, lineHeight: "24px", padding: "16px" }
};

export const menuItems: MenuItemData[] = [
    { caption: "Home", href: "/home" },
    { caption: "The Story",  href: "/story" },
    { caption: "Photo Gallery",  href: "/photo-gallery" },
    { caption: "Press",  href: "/press" },
    { caption: "Wholesale",  href: "/wholesale" },
    { caption: "Store Near You",  href: "/stores" },
];

//export const logoUrl = "other/logo_courier_black_withbg.jpg";
export const logoUrl = "other/laurainbo_mosaic_logo.jpg";

export const altLogo = "Laurainbo Crystals";

export default {
    domain,
    isDashboard,
    productGraphicsBaseUrl,
    themes,
    menuItems,
    logoUrl,
    altLogo
}
