import React from 'react';
import styled from 'styled-components';

import MasterPage from './MasterPage';
import { iPhoneWidth } from '../libs/libSupport';
import { useFetchApi, genericApiError } from '../libs/useDataApiV2';
import { StyledErrorText } from '../libs/libSupport';
import Spinner from '../libs/Spinner';

import app from '../appData';
import api from '../api-url';
import { WebsiteTagRecord, WebsiteTextContentType } from '../interfaces/lib-websites-interfaces';
import { useLocation } from 'react-router-dom';
import { formatImageUrl } from '../libs/ImageFormatter';
import InfoPageViewer from '../libs/InfoPageViewer';

const MasterContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1000px;
    font-size: 16px;
    line-height: 24px;
    p {
        margin-top: 16px;
        margin-bottom: 0;
        text-align: left;
    }
    @media (max-width: ${iPhoneWidth}px) {
        p {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            font-size: 17px;
        }
    }
`
const StoryImageDiv = styled.div<{ float: string }>`
    width: 33%;
    margin-left: ${props => props.float === "right" ? "16px" : 0};
    margin-right: ${props => props.float === "left" ? "38px" : 0};
    float: ${props => props.float};
`
const StoryImage = styled.img<{ imageBorder: string }>`
    width: 100%;
    height: auto;
    display: block;
    border: ${props => props.imageBorder};
`
const DividerImages = styled.div`
    display: flex;
    width: 275px;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
`
const GroupedImagesDiv = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
`
const GroupedImageDiv = styled.div<{ imageBorder: string }>`
    width: 40%;
    height: auto;
    border: ${props => props.imageBorder};
`
const GroupedImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
`

const leftImageUrl = "other/lauraine_with_mosaic.jpg";
const leftImageAlt = "Lauraine in her studio";
const rightImageUrl = "other/in_studio.jpg";
const rightImageAlt = "Lauraine working in her studio";

interface StoryContentRecord {
    above: WebsiteTextContentType;      // the properties must match the tags
    below: WebsiteTextContentType;
}
const Story: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [content, setContent] = React.useState<StoryContentRecord>();
    const { fetch, isFetchLoading } = useFetchApi();
    const location = useLocation();

    const gotData = (data: WebsiteTagRecord[]) => {
        const record = {} as StoryContentRecord;
        record[data[0].tag as keyof StoryContentRecord] = data[0].content as WebsiteTextContentType;
        record[data[1].tag as keyof StoryContentRecord] = data[1].content as WebsiteTextContentType;
        setContent(record);
    }

    const isDashboard = location.search.includes("dashboard");
    React.useEffect(() => {
        if (!content) {
            fetch(api.fetchContent + "story/" + (isDashboard ? 1 : 0), null, gotData, () => setErrorMsg(genericApiError));
        }
    }, []);

    if (!content) {
        return null;
    }
    const dividerUrl = formatImageUrl("other/divider_crystal.gif");
    return (
        <MasterPage>
            <MasterContainer>
                <h1>The Story</h1>
                {isFetchLoading() && <Spinner />}
                {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                {window.matchMedia("(max-width: " + iPhoneWidth + "px)").matches ? (
                    <GroupedImagesDiv>
                        <GroupedImageDiv imageBorder={app.themes.imageBorder}>
                            <GroupedImage src={formatImageUrl(leftImageUrl)} alt={leftImageAlt} />
                        </GroupedImageDiv>
                        <GroupedImageDiv imageBorder={app.themes.imageBorder}>
                            <GroupedImage src={formatImageUrl(rightImageUrl)} alt={rightImageAlt} />
                        </GroupedImageDiv>
                    </GroupedImagesDiv>
                ) : (
                    <React.Fragment>
                        <StoryImageDiv float="left">
                            <StoryImage imageBorder={app.themes.imageBorder} src={formatImageUrl(leftImageUrl)} alt={leftImageAlt} />
                        </StoryImageDiv>
                        <StoryImageDiv float="right">
                            <StoryImage imageBorder={app.themes.imageBorder} src={formatImageUrl(rightImageUrl)} alt={rightImageAlt} />
                        </StoryImageDiv>
                    </React.Fragment>
                )}
                
                <p dangerouslySetInnerHTML={{ __html: content.above.text }} />
                <DividerImages>
                    <img src={dividerUrl} alt="divider" />
                    <img src={dividerUrl} alt="divider" />
                    <img src={dividerUrl} alt="divider" />
                </DividerImages>
                <p dangerouslySetInnerHTML={{ __html: content.below.text }} />
            </MasterContainer>
        </MasterPage>
    );
}
export default Story;

{/* <InfoPageViewer infoRecord={{ content: content.above.text, settings: { styles: content.above.styles, allowImages: false } }} />
         <InfoPageViewer infoRecord={{ content: content.below.text, settings: { styles: content.below.styles, allowImages: false } }} /> */}
