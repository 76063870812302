import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import MasterPage from './MasterPage';
import { iPhoneWidth } from '../libs/libSupport';
import { useFetchApi, genericApiError } from '../libs/useDataApiV2';
import { StyledErrorText } from '../libs/libSupport';
import Spinner from '../libs/Spinner';
import { formatImageUrl } from '../libs/ImageFormatter';

import { WebsitePhotoContentType, WebsiteTagRecord, WebsiteTextPairContentType } from '../interfaces/lib-websites-interfaces';

import app from '../appData';
import api from '../api-url';

const MasterContainer = styled.div`
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    h2 {
        font-size: 30px;
    }
`
const MainImage = styled.img<{ maxWidth: number; imageBorder: string }>`
    max-width: ${props => props.maxWidth}px;
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    border: ${props => props.imageBorder};
    @media (max-width: ${iPhoneWidth}px) {
        width: 90%;
    }
`
const BulletPoint = styled.p`
    width: 100%;
    margin-top: 24px;
    @media (max-width: ${iPhoneWidth}px) {
        width: 90%;
    }
`
const BoldSpan = styled.span`
    font-weight: bold;
`
interface BulletPointRecord {
    label: string;
    text: string;
}
interface WholesaleContentRecord {
    photo: WebsitePhotoContentType;
    items: WebsiteTextPairContentType;
}
const Wholesale: React.FC = (props) => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [content, setContent] = React.useState<WholesaleContentRecord>();
    const { fetch, isFetchLoading } = useFetchApi();
    const location = useLocation();

    const isDashboard = location.search.includes("dashboard");
    const gotData = (data: WebsiteTagRecord[]) => {
        const record = {} as WholesaleContentRecord;
        record[data[0].tag as keyof WholesaleContentRecord] = data[0].content as any;
        record[data[1].tag as keyof WholesaleContentRecord] = data[1].content as any;
        setContent(record);
    }
    React.useEffect(() => {
        if (!content) {
            fetch(api.fetchContent + "wholesale/" + (isDashboard ? 1 : 0), null, gotData, () => setErrorMsg(genericApiError));
        }
    }, []);

    return (
        <MasterPage>
            {content &&
                <MasterContainer>
                    <h1>Wholesale</h1>
                    {isFetchLoading() && <Spinner />}
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    <MainImage maxWidth={content.photo.images[0].size ?? content.photo.imageOptions.fileOptions!.size!} imageBorder={app.themes.imageBorder} 
                        src={formatImageUrl(content.photo.images[0].filename!, { graphicsSubfolder: content.photo.imageOptions.fileOptions!.graphicsSubfolder })}
                        alt={content.photo.images[0].alt} />
                    <h2>WHOLESALE ORDERING INFO</h2>
                    {content.items.map(((point: BulletPointRecord) => {
                        return (
                            <BulletPoint key={point.label}>
                                <BoldSpan>{point.label}:&nbsp;</BoldSpan>
                                {point.text}
                            </BulletPoint>
                        )
                    }))}
                </MasterContainer>
            }
        </MasterPage>
    )
}

export default Wholesale;
