import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './components/Home';
import Stores from './components/Stores';
import Story from './components/Story';
import Wholesale from './components/Wholesale';
import Press from './components/Press';
import Gallery from './components/Gallery';

const AppRoutes = () => {
     return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/story" element={<Story />} />
                <Route path="/wholesale" element={<Wholesale />} />
                <Route path="/stores" element={<Stores />} />
                <Route path="/press" element={<Press />} />
                <Route path="/photo-gallery" element={<Gallery />} />
            </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;