import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import NavBar from '../libs/NavBarV4';
import { iPhoneWidth } from '../libs/libSupport';

import app from '../appData';
import { formatImageUrl } from '../libs/ImageFormatter';

const ContentAndCart = styled.div`
    display: flex;
    flex-direction: row;
`
const StyledMasterContainer = styled.div<{ defaultFonts: string; h1FontFamily: string; foreColor: string; bgImage: string }>`
    text-align: center;
    font-family: ${props => props.defaultFonts};
    color: ${props => props.foreColor};
    background-image: url(${props => props.bgImage});
    background-size: cover;
    @media screen and (max-width: 750px) {
        text-align: left;
    }
    a:link {
        text-decoration: none; border: none; color: ${props => props.foreColor}
    }
    a:visited {
        text-decoration: none; color: ${props => props.foreColor}
    }
    a:hover {
        font-style: italic; text-decoration: underline; color: ${props => props.foreColor}
    }
    h1 {
        font-family: ${props => props.h1FontFamily};
        text-align: center;
        font-size: 36px;
        margin-top: 24px;
        margin-bottom: 4px;
        font-weight: bold;
        @media (max-width: ${iPhoneWidth}px) {
            font-size: 24px;
            margin-bottom: 8px;
        }
    }
    h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
    @media (max-width: ${iPhoneWidth}px) {
        font-size: 14px;
    }

`
const StyledContentContainer = styled.div`
    width: 100%;
`
const LogoImage = styled.img` 
    width: 100%;
    height: auto;
    margin: 0;
    display: block;
`
// banner above logo shown in reverse
const StyledBanner = styled.div<{ fontFamily: string; bgImage: string }>`
    position: relative;
    display: inline-block;
    width: 100%;
    color: white;
    font-weight: bold;
    text-align: center;
    height: 48px;
    font-family: ${props => props.fontFamily};
    background-image: url(${props => props.bgImage});
    a:link {
        color: white;
        text-decoration: none;
    }
    p a:link {
        text-decoration: underline; color: blue;
    }
    p {
        font-size: 24px;
        line-height: 24px;
        margin-top: 10px;
    }
    span {
        position: fixed;
        right: 32px;
        font-size: 24px;
        top: 14px;
        color: white;
    }
    @media (max-width: ${iPhoneWidth}px) {
        p {
            font-size: 18px;
            line-height: 18px;
        }
        span {
            font-size: 16px;
            top: 18px;
            right: 16px;
        }
    }
    
`
const FacebookIcon = styled.span`
    position: fixed;
    right: 32px;
    font-size: 30px;
    top: 12px;
`
const FooterDivider = styled.div`
    margin-top: 32px;
    margin-bottom: 24px;
    width: 100%;
    height: 3px;
    background-color: ${props => props.color};
`
const FooterBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`
const AddressText = styled.p`
    text-align: center;
    width: 100%;
    margin-top: 8px;
    font-size: 16px;
    @media (max-width: ${iPhoneWidth}px) {
        font-size: 14px;
        line-height: 18px;
    }
`

interface WindowWithPaq extends Window {
    _paq: any;
}
const MasterPage: React.FC<React.PropsWithChildren<{}>> = (props) => {
        const location = useLocation();

        React.useEffect(() => {
        const win = window as unknown as WindowWithPaq;
        win._paq.push(['setCustomUrl', location.pathname]);
        win._paq.push(['setDocumentTitle', location.pathname]);
        win._paq.push(['trackPageView']);
    }, [location]);

   return (
        <StyledMasterContainer defaultFonts={app.themes.defaultFonts} h1FontFamily={app.themes.h1FontFamily ? app.themes.h1FontFamily : app.themes.defaultFonts}
            foreColor={app.themes.foreColor} bgImage={formatImageUrl(app.themes.pageBackgroundImage)}>
            <StyledBanner bgImage={formatImageUrl(app.themes.bannerBackgroundImage)} fontFamily={app.themes.bannerFont}>
                <p>
                    Calling in the light since 1984!
                 </p>
                <a href="https://www.facebook.com/laurainbocrystals/">
                    <span>
                        <i className="fab fa-facebook-f"></i>
                    </span>
                </a>
            </StyledBanner>
            <Link to="/home"><LogoImage src={formatImageUrl(app.logoUrl)} alt="Laurainbo Crystals" /></Link>
            <NavBar mainOptions={{height: 48, fontSize: 24, backgroundImage: app.themes.bannerBackgroundImage, 
                hoverForeColor: app.themes.bannerBgImageAsSolid, hoverBackColor: "white"}} 
                menuItems={app.menuItems} />
            <ContentAndCart>
                <StyledContentContainer>
                    <React.Fragment>
                        {props.children}
                    </React.Fragment>
                </StyledContentContainer>
            </ContentAndCart>
            <FooterDivider color={app.themes.foreColor} />
            <FooterBox>
                <AddressText>
                    Lauraine Aïssata Alden, Owner and Designer<br />
                    P.O. Box 5596, Berkeley, CA 94705<br />
                    Email: <a href="mailto:laurainbo@gmail.com"><u>Laurainbo@gmail.com</u></a>&nbsp;&bull;&nbsp;Call: <a href="tel:707-834-9369"><u>707-834-9369</u></a>
                </AddressText>
            </FooterBox>
        </StyledMasterContainer>
    );
}

export default MasterPage;
